import React from 'react'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../authConfig'
import './login.css'
import caremilesLogo from 'src/assets/images/caremiles-logo.png'
import {
  CContainer,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CRow,
  CImage,
} from '@coreui/react'
/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
  const { instance } = useMsal()

  const handleLogin = (loginType) => {
    if (loginType === 'popup') {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e)
      })
    } else if (loginType === 'redirect') {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e)
      })
    }
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <section className="h-100">
                    <div className="container h-100">
                      <div className="d-flex align-items-center justify-content-center h-100">
                        <div className="d-flex flex-column loginContainer">
                          <div className="d-flex flex-column loginContainer">
                            <CImage src={caremilesLogo} alt="Logo" />
                          </div>
                          <h1>Sign In</h1>
                          <p className="text-medium-emphasis margin-10">Sign In to your account</p>
                          <div className="d-flex col-12 align-items-center justify-content-center h-100">
                            <CButton
                              className="btn border-fourth bg-fourth text-light active"
                              size="md"
                              onClick={() => handleLogin('redirect')}
                            >
                              Sign In
                            </CButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}
