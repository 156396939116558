import React, { Component, useState } from 'react'
import { HashRouter, Link, Route, Switch } from 'react-router-dom'
import './scss/style.scss'

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react'
import { loginRequest } from './authConfig'
import { PageLayout } from './components/login/PageLayout'
import { ProfileData } from './components/login/ProfileData'
import { callMsGraph } from './graph'
import Button from 'react-bootstrap/Button'
import './styles/App.css'

import {
  CContainer,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { SignInButton } from './components/login/SignInButton'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
  const { instance, accounts } = useMsal()
  const [graphData, setGraphData] = useState(null)

  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) => setGraphData(response))
      })
  }

  return (
    <>
      <h5 className="card-title">Welcome {accounts[0].name}</h5>
      {graphData ? (
        <ProfileData graphData={graphData} />
      ) : (
        <Button variant="secondary" onClick={RequestProfileData}>
          Request Profile Information
        </Button>
      )}
    </>
  )
}

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
class App1 extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route
              exact
              path="/dashboard"
              name="Dashboard"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/members"
              name="Members"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/vehicles"
              name="Vehicles"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/trees"
              name="Trees"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/teams"
              name="Teams"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/dashboard"
              name="Dashboard"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/notify"
              name="Notifications"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/qr"
              name="QR Links"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/createQR"
              name="Create QR Link"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/createTeam"
              name="Create Team"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/editTeam"
              name="Edit Team"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/createNotification"
              name="Create Notification"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/friends"
              name="Friends"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              path="/team-members"
              name="Team Members"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              path="/wallet-details"
              name="Wallet Details"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              path="/createVehicle"
              name="Create Vehicle"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/editQR"
              name="Edit QR Link"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/configurations"
              name="Configurations"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/states"
              name="States"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/editState"
              name="Edit State"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/addState"
              name="Create State"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/evclub"
              name="EV Club"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/evclubcampaigns"
              name="EV Club Campaigns"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/evclubcampaignview"
              name="Campaign"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/wallet"
              name="Wallet"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/brands"
              name="Brands"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/editBrand"
              name="Edit Brand"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/createBrand"
              name="Create Brand"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/projects"
              name="Projects"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/editProject"
              name="Edit Project"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/createProject"
              name="Create Project"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/campaigns"
              name="Campaigns"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/editCampaign"
              name="Edit Campaign"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/createCampaign"
              name="Create Campaign"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/createEmail"
              name="Create Email"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/invitation"
              name="EV Club Website emails"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route exact path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
            <Route path="*" component={Page404} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    )
  }
}

const MainContent = () => {
  const isAuthenticated = useIsAuthenticated()

  return <>{isAuthenticated ? <App1 /> : <></>}</>
}

export default function App() {
  //return <App1 />
  return (
    <PageLayout>
      <MainContent />
    </PageLayout>
  )
}

//export default App
